import $ from "jquery";
import popper from "popper.js";
import bootstrap from "bootstrap";
import slick from "slick-carousel";
import spritespin from "spritespin";


$(function(){
var frames = [
"img/3d/j1_00000.jpg",
"img/3d/j1_00001.jpg",
"img/3d/j1_00002.jpg",
"img/3d/j1_00003.jpg",
"img/3d/j1_00004.jpg",
"img/3d/j1_00005.jpg",
"img/3d/j1_00006.jpg",
"img/3d/j1_00007.jpg",
"img/3d/j1_00008.jpg",
"img/3d/j1_00009.jpg",
"img/3d/j1_00010.jpg",
"img/3d/j1_00011.jpg",
"img/3d/j1_00012.jpg",
"img/3d/j1_00013.jpg",
"img/3d/j1_00014.jpg",
"img/3d/j1_00015.jpg",
"img/3d/j1_00016.jpg",
"img/3d/j1_00017.jpg",
"img/3d/j1_00018.jpg",
"img/3d/j1_00019.jpg",
"img/3d/j1_00020.jpg",
"img/3d/j1_00021.jpg",
"img/3d/j1_00022.jpg",
"img/3d/j1_00023.jpg",
"img/3d/j1_00024.jpg",
"img/3d/j1_00025.jpg",
"img/3d/j1_00026.jpg",
"img/3d/j1_00027.jpg",
"img/3d/j1_00028.jpg",
"img/3d/j1_00029.jpg",
"img/3d/j1_00030.jpg",
"img/3d/j1_00031.jpg",
"img/3d/j1_00032.jpg",
"img/3d/j1_00033.jpg",
"img/3d/j1_00034.jpg",
"img/3d/j1_00035.jpg",
"img/3d/j1_00036.jpg",
"img/3d/j1_00037.jpg",
"img/3d/j1_00038.jpg",
"img/3d/j1_00039.jpg",
"img/3d/j1_00040.jpg",
"img/3d/j1_00041.jpg",
"img/3d/j1_00042.jpg",
"img/3d/j1_00043.jpg",
"img/3d/j1_00044.jpg",
"img/3d/j1_00045.jpg",
"img/3d/j1_00046.jpg",
"img/3d/j1_00047.jpg",
"img/3d/j1_00048.jpg",
"img/3d/j1_00049.jpg",
"img/3d/j1_00050.jpg",
"img/3d/j1_00051.jpg",
"img/3d/j1_00052.jpg",
"img/3d/j1_00053.jpg",
"img/3d/j1_00054.jpg",
"img/3d/j1_00055.jpg",
"img/3d/j1_00056.jpg",
"img/3d/j1_00057.jpg",
"img/3d/j1_00058.jpg",
"img/3d/j1_00059.jpg",
"img/3d/j1_00060.jpg",
"img/3d/j1_00061.jpg",
"img/3d/j1_00062.jpg",
"img/3d/j1_00063.jpg",
"img/3d/j1_00064.jpg",
"img/3d/j1_00065.jpg",
"img/3d/j1_00066.jpg",
"img/3d/j1_00067.jpg",
"img/3d/j1_00068.jpg",
"img/3d/j1_00069.jpg",
"img/3d/j1_00070.jpg",
"img/3d/j1_00071.jpg",
"img/3d/j1_00072.jpg",
"img/3d/j1_00073.jpg",
"img/3d/j1_00074.jpg",
"img/3d/j1_00075.jpg",
"img/3d/j1_00076.jpg",
"img/3d/j1_00077.jpg",
"img/3d/j1_00078.jpg",
"img/3d/j1_00079.jpg",
"img/3d/j1_00080.jpg",
"img/3d/j1_00081.jpg",
"img/3d/j1_00082.jpg",
"img/3d/j1_00083.jpg",
"img/3d/j1_00084.jpg",
"img/3d/j1_00085.jpg",
"img/3d/j1_00086.jpg",
"img/3d/j1_00087.jpg",
"img/3d/j1_00088.jpg",
"img/3d/j1_00089.jpg",
"img/3d/j1_00090.jpg",
"img/3d/j1_00091.jpg",
"img/3d/j1_00092.jpg",
"img/3d/j1_00093.jpg",
"img/3d/j1_00094.jpg",
"img/3d/j1_00095.jpg",
"img/3d/j1_00096.jpg",
"img/3d/j1_00097.jpg",
"img/3d/j1_00098.jpg",
"img/3d/j1_00099.jpg",
"img/3d/j1_00100.jpg",
"img/3d/j1_00101.jpg",
"img/3d/j1_00102.jpg",
"img/3d/j1_00103.jpg",
"img/3d/j1_00104.jpg",
"img/3d/j1_00105.jpg",
"img/3d/j1_00106.jpg",
"img/3d/j1_00107.jpg",
"img/3d/j1_00108.jpg",
"img/3d/j1_00109.jpg",
"img/3d/j1_00110.jpg",
"img/3d/j1_00111.jpg",
"img/3d/j1_00112.jpg",
"img/3d/j1_00113.jpg",
"img/3d/j1_00114.jpg",
"img/3d/j1_00115.jpg",
"img/3d/j1_00116.jpg",
"img/3d/j1_00117.jpg",
"img/3d/j1_00118.jpg",
"img/3d/j1_00119.jpg",
"img/3d/j1_00120.jpg",
"img/3d/j1_00121.jpg",
"img/3d/j1_00122.jpg",
"img/3d/j1_00123.jpg",
"img/3d/j1_00124.jpg",
"img/3d/j1_00125.jpg",
"img/3d/j1_00126.jpg",
"img/3d/j1_00127.jpg",
"img/3d/j1_00128.jpg",
"img/3d/j1_00129.jpg",
"img/3d/j1_00130.jpg",
"img/3d/j1_00131.jpg",
"img/3d/j1_00132.jpg",
"img/3d/j1_00133.jpg",
"img/3d/j1_00134.jpg",
"img/3d/j1_00135.jpg",
"img/3d/j1_00136.jpg",
"img/3d/j1_00137.jpg",
"img/3d/j1_00138.jpg",
"img/3d/j1_00139.jpg",
"img/3d/j1_00140.jpg",
"img/3d/j1_00141.jpg",
"img/3d/j1_00142.jpg",
"img/3d/j1_00143.jpg",
"img/3d/j1_00144.jpg",
"img/3d/j1_00145.jpg",
"img/3d/j1_00146.jpg",
"img/3d/j1_00147.jpg",
"img/3d/j1_00148.jpg",
"img/3d/j1_00149.jpg",
"img/3d/j1_00150.jpg",
"img/3d/j1_00151.jpg",
"img/3d/j1_00152.jpg",
"img/3d/j1_00153.jpg",
"img/3d/j1_00154.jpg",
"img/3d/j1_00155.jpg",
"img/3d/j1_00156.jpg",
"img/3d/j1_00157.jpg",
"img/3d/j1_00158.jpg",
"img/3d/j1_00159.jpg",
"img/3d/j1_00160.jpg",
"img/3d/j1_00161.jpg",
"img/3d/j1_00162.jpg",
"img/3d/j1_00163.jpg",
"img/3d/j1_00164.jpg",
"img/3d/j1_00165.jpg",
"img/3d/j1_00166.jpg",
"img/3d/j1_00167.jpg",
"img/3d/j1_00168.jpg",
"img/3d/j1_00169.jpg",
"img/3d/j1_00170.jpg",
"img/3d/j1_00171.jpg",
"img/3d/j1_00172.jpg",
"img/3d/j1_00173.jpg",
"img/3d/j1_00174.jpg",
"img/3d/j1_00175.jpg",
"img/3d/j1_00176.jpg",
"img/3d/j1_00177.jpg",
"img/3d/j1_00178.jpg",
"img/3d/j1_00179.jpg",
"img/3d/j1_00180.jpg",
"img/3d/j1_00181.jpg",
"img/3d/j1_00182.jpg",
"img/3d/j1_00183.jpg",
"img/3d/j1_00184.jpg",
"img/3d/j1_00185.jpg",
"img/3d/j1_00186.jpg",
"img/3d/j1_00187.jpg",
"img/3d/j1_00188.jpg",
"img/3d/j1_00189.jpg",
"img/3d/j1_00190.jpg",
"img/3d/j1_00191.jpg",
"img/3d/j1_00192.jpg",
"img/3d/j1_00193.jpg",
"img/3d/j1_00194.jpg",
"img/3d/j1_00195.jpg",
"img/3d/j1_00196.jpg",
"img/3d/j1_00197.jpg",
"img/3d/j1_00198.jpg",
"img/3d/j1_00199.jpg",
"img/3d/j1_00200.jpg",
"img/3d/j1_00201.jpg",
"img/3d/j1_00202.jpg",
"img/3d/j1_00203.jpg",
"img/3d/j1_00204.jpg",
"img/3d/j1_00205.jpg",
"img/3d/j1_00206.jpg",
"img/3d/j1_00207.jpg",
"img/3d/j1_00208.jpg",
"img/3d/j1_00209.jpg",
"img/3d/j1_00210.jpg",
"img/3d/j1_00211.jpg",
"img/3d/j1_00212.jpg",
"img/3d/j1_00213.jpg",
"img/3d/j1_00214.jpg",
"img/3d/j1_00215.jpg",
"img/3d/j1_00216.jpg",
"img/3d/j1_00217.jpg",
"img/3d/j1_00218.jpg",
"img/3d/j1_00219.jpg",
"img/3d/j1_00220.jpg",
"img/3d/j1_00221.jpg",
"img/3d/j1_00222.jpg",
"img/3d/j1_00223.jpg",
"img/3d/j1_00224.jpg",
"img/3d/j1_00225.jpg",
"img/3d/j1_00226.jpg",
"img/3d/j1_00227.jpg",
"img/3d/j1_00228.jpg",
"img/3d/j1_00229.jpg",
"img/3d/j1_00230.jpg",
"img/3d/j1_00231.jpg",
"img/3d/j1_00232.jpg",
"img/3d/j1_00233.jpg",
"img/3d/j1_00234.jpg",
"img/3d/j1_00235.jpg",
"img/3d/j1_00236.jpg",
"img/3d/j1_00237.jpg",
"img/3d/j1_00238.jpg",
"img/3d/j1_00239.jpg",
"img/3d/j1_00240.jpg",
"img/3d/j1_00241.jpg",
"img/3d/j1_00242.jpg",
"img/3d/j1_00243.jpg",
"img/3d/j1_00244.jpg",
"img/3d/j1_00245.jpg",
"img/3d/j1_00246.jpg",
"img/3d/j1_00247.jpg",
"img/3d/j1_00248.jpg",
"img/3d/j1_00249.jpg",
"img/3d/j1_00250.jpg",
"img/3d/j1_00251.jpg",
"img/3d/j1_00252.jpg",
"img/3d/j1_00253.jpg",
"img/3d/j1_00254.jpg",
"img/3d/j1_00255.jpg",
"img/3d/j1_00256.jpg",
"img/3d/j1_00257.jpg",
"img/3d/j1_00258.jpg",
"img/3d/j1_00259.jpg",
"img/3d/j1_00260.jpg",
"img/3d/j1_00261.jpg",
"img/3d/j1_00262.jpg",
"img/3d/j1_00263.jpg",
"img/3d/j1_00264.jpg",
"img/3d/j1_00265.jpg",
"img/3d/j1_00266.jpg",
"img/3d/j1_00267.jpg",
"img/3d/j1_00268.jpg",
"img/3d/j1_00269.jpg",
"img/3d/j1_00270.jpg",
"img/3d/j1_00271.jpg",
"img/3d/j1_00272.jpg",
"img/3d/j1_00273.jpg",
"img/3d/j1_00274.jpg",
"img/3d/j1_00275.jpg",
"img/3d/j1_00276.jpg",
"img/3d/j1_00277.jpg",
"img/3d/j1_00278.jpg",
"img/3d/j1_00279.jpg",
"img/3d/j1_00280.jpg",
"img/3d/j1_00281.jpg",
"img/3d/j1_00282.jpg",
"img/3d/j1_00283.jpg",
"img/3d/j1_00284.jpg",
"img/3d/j1_00285.jpg",
"img/3d/j1_00286.jpg",
"img/3d/j1_00287.jpg",
"img/3d/j1_00288.jpg",
"img/3d/j1_00289.jpg",
"img/3d/j1_00290.jpg",
"img/3d/j1_00291.jpg",
"img/3d/j1_00292.jpg",
"img/3d/j1_00293.jpg",
"img/3d/j1_00294.jpg",
"img/3d/j1_00295.jpg",
"img/3d/j1_00296.jpg",
"img/3d/j1_00297.jpg",
"img/3d/j1_00298.jpg",
];


if ($(window).width() > 768) {
  $("#frames").spritespin({
  width : 511,
  height: 598,
  frames: frames.length,
  behavior: "drag", // "hold"
  module: "360",
  sense : -1,
  source: frames,
  animate : true,
  loop: true,
  frameWrap : true,
  frameStep : 1,
  frameTime : 60,
  enableCanvas : true
  });

}

});


$('.show-menu, .close-menu').click(function(event) {
	$('.show-menu').toggleClass('open');
	$('.header-mob-wrap').slideToggle();
	$('body').toggleClass('open');
	$('.bg-plan').fadeToggle();
});

$(document).click(function(e) {
	 if ($(window).width() < 1200) { 
    if (!$(e.target).is('.show-menu, .show-menu * , .header-mob-wrap, .header-mob-wrap *')) {
      $('.show-menu').removeClass('open');
			$('.choose-town').fadeOut();
			$('.header-mob-wrap').slideUp();
			$('body').removeClass('open');
			$('.bg-plan').fadeOut();
    }
}
  
});

if($('body .bg-plan').length){
  $('body .bg-plan').remove();
  $('body').prepend('<div class="bg-plan"></div>');
}
else {
  $('body').prepend('<div class="bg-plan"></div>');
}

$('body').on('click', '.bg-plan', function(event) {
	$('.choose-town').fadeOut();
			$('.header-mob-wrap').slideUp();
			$('body').removeClass('open');
			$('.bg-plan').fadeOut();
	event.preventDefault();
});

var nowPlaying = "none";



$('.video-frame').on('click', function(ev) {

	$(this).fadeOut(0);
	$(this).parent().find('.hide-video').fadeIn();
 
    $(this).parent().find('iframe')[0].src += "&autoplay=1";
    ev.preventDefault();
 
  });


$(document).on('click', 'nav a[href^="#"]', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top - $('header').height()
    }, 500);
});

$(document).on('click', '.hero-see-det a[href^="#"]', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top - $('header').height()
    }, 500);
});



$('nav a').click(function(event) {
	if ($(window).width() < 1200) {
		$('.show-menu').removeClass('open');
		$('.choose-town').fadeOut();
		$('.header-mob-wrap').slideUp();
		$('body').removeClass('open');
		$('.bg-plan').fadeOut();
	}
});


function inArray(needle, haystack) {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(typeof haystack[i] == 'object') {
            if(arrayCompare(haystack[i], needle)) return true;
        } else {
            if(haystack[i] == needle) return true;
        }
    }
    return false;
}
window.isset = function (v) {
    if (typeof(v) == 'object' && v == 'undefined') {
        return false;
    } else  if (arguments.length === 0) {
        return false;
    } else {
        var buff = arguments[0];
        for (var i = 0; i < arguments.length; i++){
            if (typeof(buff) === 'undefined' || buff === null) return false;
            buff = buff[arguments[i+1]];
        }
    }
    return true;
}

function myconf() {
    var cf = $.Deferred();
        $.ajax({
            type: 'POST',
            url: 'feedback/',
            dataType: 'json',
            data: 'act=cfg',
            success: function(answer) {
                cf.resolve(answer.configs);
            }
        });
    return cf;
}

var mcf = myconf();

mcf.done(function(conf) {

$(document).ready(function() {
(function() {
           var fb = $('.feedback');
           if(fb.length > 0) {
                fb.each(function(){
                    var form = $(this).closest('form'), name = form.attr('name');
                    //console.log(form);
                    if(isset(conf[name]) && isset(conf[name].cfg.antispamjs)) {
                      $(form).prepend('<input type="text" name="'+ conf[name].cfg.antispamjs +'" value="tesby" style="display:none;">');
                    }
                });
            }
  })();
});


/**
 * Отправка форм.
 *
 */

function feedback(vars) {
    var bt = $(vars.form).find('.feedback');
    var btc = bt.clone();
    var bvc = bt.val();
    var cfg = conf[vars.act].cfg;

    $.ajax({
        type: 'POST',
        url: 'feedback/',
        cache: false,
        dataType: 'json',
        data: 'act=' + vars.act + '&' + vars.data,
        beforeSend: function() {
            //$(bt).val('');
            $(bt).prop("disabled", true);
            $(bt).addClass('loading');
        },
        success: function(answer) {
            //console.log(cfg);
          if(isset(cfg.notify) && !/none/i.test(cfg.notify)) {

             if(/textbox/i.test(cfg.notify)) {
                    if(isset(answer.errors)) {
                        $.each(answer.errors, function(k,val) {
                            $.jGrowl(val, {theme: 'error', header: 'Ошибка!', life: 3000 });
                        });
                    } if(isset(answer.infos)) {
                        $.each(answer.infos, function(k,val) {
                            $.jGrowl(val, {theme: 'infos', header: 'Внимание!', life: 3000 });
                        });
                    }

            }  if(/color/i.test(cfg.notify)) {
                 $(vars.form).find('input[type=text]:visible, textarea:visible, select:visible').css({'border-bottom': '1px solid #D7D5CF'}, 300);
                 if(isset(answer.errors)) {
                     $.each(answer.errors, function(k,val) {
                         var reg = /[a-z]/i;
                         if(reg.test(k)) {
                          var e = $(vars.form).find('[name='+ k +']');
                          if(e.length == 1) {
                           $(e).css({'border-bottom': '1px solid #FF532E'}, 100);
                          }
                        }
                     });
                 } if(isset(answer.infos)) {
                      var li='', $inf = $('<ul>', {id:'feedback-infolist'});
                       $.each(answer.infos, function(k,val) {
                          li += '<li>'+ val +'</li>';
                       });

                      $inf.html(li);

                      //$.arcticmodal('close');

                      if(/modal/i.test(cfg.notify)) {
                          var m = $('<div class="box-modal" id="feedback-modal-box" />');
                          m.html($inf);
                          m.prepend('<div class="modal-close arcticmodal-close">X</div>');
                          
                      }
                       //bt.replaceWith($inf);

                     /* setInterval(function(){
                        //$('#feedback-inf-box').replaceWith(btc);
                        $('#feedback-modal-box').arcticmodal('close');
                      }, 4000);*/
                  }

            }
          }
            $(bt).prop("disabled", false);
            $(bt).removeClass('loading');
            //$(bt).val(bvc);

            if(isset(answer.ok) && answer.ok == 1) {
                $(vars.form)[0].reset();
                $('#feed').modal('toggle');
            }
        }
    });

}

    $(document).on('mouseenter mouseover', '.feedback', function(){
        var form = $(this).closest('form'), name = form.attr('name');
        if(isset(conf[name]) && isset(conf[name].cfg.antispamjs)) {
            $('input[name='+ conf[name].cfg.antispamjs +']').val('');
        }
    });


/**
 * Обработчик кнопки форм.
 * Кнопка должна быть внутри тегов <form> c классом .feedback
 * будет отправлено любое кол-во полей, кроме файлов
 *
 */

$(document).on('click', '.feedback', function(){
   var form = $(this).closest('form'), name = form.attr('name'), obj = {};
       obj.form = form;
       obj.act = name;
       obj.data = $(form).serialize();

      feedback(obj);

    return false;
});

}); // done




	/** Compatibility holdover for 1.9 to check IE6 **/
	var $ie6 = (function(){
		return false === $.support.boxModel && $.support.objectAll && $support.leadingWhitespace;
	})();

	/** jGrowl Wrapper - Establish a base jGrowl Container for compatibility with older releases. **/
	$.jGrowl = function( m , o ) {
		// To maintain compatibility with older version that only supported one instance we'll create the base container.
		if ( $('#jGrowl').length == 0 ) 
			$('<div id="jGrowl"></div>').addClass( (o && o.position) ? o.position : $.jGrowl.defaults.position ).appendTo('body');

		// Create a notification on the container.
		$('#jGrowl').jGrowl(m,o);
	};


	/** Raise jGrowl Notification on a jGrowl Container **/
	$.fn.jGrowl = function( m , o ) {
		if ( $.isFunction(this.each) ) {
			var args = arguments;

			return this.each(function() {
				var self = this;

				/** Create a jGrowl Instance on the Container if it does not exist **/
				if ( $(this).data('jGrowl.instance') == undefined ) {
					$(this).data('jGrowl.instance', $.extend( new $.fn.jGrowl(), { notifications: [], element: null, interval: null } ));
					$(this).data('jGrowl.instance').startup( this );
				}

				/** Optionally call jGrowl instance methods, or just raise a normal notification **/
				if ( $.isFunction($(this).data('jGrowl.instance')[m]) ) {
					$(this).data('jGrowl.instance')[m].apply( $(this).data('jGrowl.instance') , $.makeArray(args).slice(1) );
				} else {
					$(this).data('jGrowl.instance').create( m , o );
				}
			});
		};
	};

	$.extend( $.fn.jGrowl.prototype , {

		/** Default JGrowl Settings **/
		defaults: {
			pool: 			0,
			header: 		'',
			group: 			'',
			sticky: 		false,
			position: 		'top-right',
			glue: 			'after',
			theme: 			'default',
			themeState: 	'',//'highlight',
			corners: 		'10px',
			check: 			250,
			life: 			3000,
			closeDuration:  'normal',
			openDuration:   'normal',
			easing: 		'swing',
			closer: 		true,
			closeTemplate: '&times;',
			closerTemplate: '<div>[ close all ]</div>',
			log: 			function(e,m,o) {},
			beforeOpen: 	function(e,m,o) {},
			afterOpen: 		function(e,m,o) {},
			open: 			function(e,m,o) {},
			beforeClose: 	function(e,m,o) {},
			close: 			function(e,m,o) {},
			animateOpen: 	{
				opacity: 	'show'
			},
			animateClose: 	{
				opacity: 	'hide'
			}
		},
		
		notifications: [],
		
		/** jGrowl Container Node **/
		element: 	null,
	
		/** Interval Function **/
		interval:   null,
		
		/** Create a Notification **/
		create: 	function( message , o ) {
			var o = $.extend({}, this.defaults, o);

			/* To keep backward compatibility with 1.24 and earlier, honor 'speed' if the user has set it */
			if (typeof o.speed !== 'undefined') {
				o.openDuration = o.speed;
				o.closeDuration = o.speed;
			}

			this.notifications.push({ message: message , options: o });
			
			o.log.apply( this.element , [this.element,message,o] );
		},
		
		render: 		function( notification ) {
			var self = this;
			var message = notification.message;
			var o = notification.options;

			// Support for jQuery theme-states, if this is not used it displays a widget header
			o.themeState = (o.themeState == '') ? '' : 'ui-state-' + o.themeState;

			var notification = $(
				'<div class="jGrowl-notification ' + o.themeState + ' ui-corner-all' + 
				((o.group != undefined && o.group != '') ? ' ' + o.group : '') + '">' +
				'<div class="jGrowl-close">' + o.closeTemplate + '</div>' +
				'<div class="jGrowl-header">' + o.header + '</div>' +
				'<div class="jGrowl-message">' + message + '</div></div>'
			).data("jGrowl", o).addClass(o.theme).children('div.jGrowl-close').bind("click.jGrowl", function() {
				$(this).parent().trigger('jGrowl.close');
			}).parent();


			/** Notification Actions **/
			$(notification).bind("mouseover.jGrowl", function() {
				$('div.jGrowl-notification', self.element).data("jGrowl.pause", true);
			}).bind("mouseout.jGrowl", function() {
				$('div.jGrowl-notification', self.element).data("jGrowl.pause", false);
			}).bind('jGrowl.beforeOpen', function() {
				if ( o.beforeOpen.apply( notification , [notification,message,o,self.element] ) != false ) {
					$(this).trigger('jGrowl.open');
				}
			}).bind('jGrowl.open', function() {
				if ( o.open.apply( notification , [notification,message,o,self.element] ) != false ) {
					if ( o.glue == 'after' ) {
						$('div.jGrowl-notification:last', self.element).after(notification);
					} else {
						$('div.jGrowl-notification:first', self.element).before(notification);
					}
					
					$(this).animate(o.animateOpen, o.openDuration, o.easing, function() {
						// Fixes some anti-aliasing issues with IE filters.
						if ($.support.opacity === false) 
							this.style.removeAttribute('filter');

						if ( $(this).data("jGrowl") != null ) // Happens when a notification is closing before it's open.
							$(this).data("jGrowl").created = new Date();
						
						$(this).trigger('jGrowl.afterOpen');
					});
				}
			}).bind('jGrowl.afterOpen', function() {
				o.afterOpen.apply( notification , [notification,message,o,self.element] );
			}).bind('jGrowl.beforeClose', function() {
				if ( o.beforeClose.apply( notification , [notification,message,o,self.element] ) != false )
					$(this).trigger('jGrowl.close');
			}).bind('jGrowl.close', function() {
				// Pause the notification, lest during the course of animation another close event gets called.
				$(this).data('jGrowl.pause', true);
				$(this).animate(o.animateClose, o.closeDuration, o.easing, function() {
					if ( $.isFunction(o.close) ) {
						if ( o.close.apply( notification , [notification,message,o,self.element] ) !== false )
							$(this).remove();
					} else {
						$(this).remove();
					}
				});
			}).trigger('jGrowl.beforeOpen');
		
			/** Optional Corners Plugin **/
			if ( o.corners != '' && $.fn.corner != undefined ) $(notification).corner( o.corners );

			/** Add a Global Closer if more than one notification exists **/
			if ( $('div.jGrowl-notification:parent', self.element).length > 1 && 
				 $('div.jGrowl-closer', self.element).length == 0 && this.defaults.closer != false ) {
				$(this.defaults.closerTemplate).addClass('jGrowl-closer ' + this.defaults.themeState + ' ui-corner-all').addClass(this.defaults.theme)
					.appendTo(self.element).animate(this.defaults.animateOpen, this.defaults.speed, this.defaults.easing)
					.bind("click.jGrowl", function() {
						$(this).siblings().trigger("jGrowl.beforeClose");

						if ( $.isFunction( self.defaults.closer ) ) {
							self.defaults.closer.apply( $(this).parent()[0] , [$(this).parent()[0]] );
						}
					});
			};
		},

		/** Update the jGrowl Container, removing old jGrowl notifications **/
		update:	 function() {
			$(this.element).find('div.jGrowl-notification:parent').each( function() {
				if ( $(this).data("jGrowl") != undefined && $(this).data("jGrowl").created != undefined && 
					 ($(this).data("jGrowl").created.getTime() + parseInt($(this).data("jGrowl").life))  < (new Date()).getTime() && 
					 $(this).data("jGrowl").sticky != true && 
					 ($(this).data("jGrowl.pause") == undefined || $(this).data("jGrowl.pause") != true) ) {

					// Pause the notification, lest during the course of animation another close event gets called.
					$(this).trigger('jGrowl.beforeClose');
				}
			});

			if ( this.notifications.length > 0 && 
				 (this.defaults.pool == 0 || $(this.element).find('div.jGrowl-notification:parent').length < this.defaults.pool) )
				this.render( this.notifications.shift() );

			if ( $(this.element).find('div.jGrowl-notification:parent').length < 2 ) {
				$(this.element).find('div.jGrowl-closer').animate(this.defaults.animateClose, this.defaults.speed, this.defaults.easing, function() {
					$(this).remove();
				});
			}
		},

		/** Setup the jGrowl Notification Container **/
		startup:	function(e) {
			this.element = $(e).addClass('jGrowl').append('<div class="jGrowl-notification"></div>');
			this.interval = setInterval( function() { 
				$(e).data('jGrowl.instance').update(); 
			}, parseInt(this.defaults.check));
			
			if ($ie6) {
				$(this.element).addClass('ie6');
			}
		},

		/** Shutdown jGrowl, removing it and clearing the interval **/
		shutdown:   function() {
			$(this.element).removeClass('jGrowl').find('div.jGrowl-notification').remove();
			clearInterval( this.interval );
		},
		
		close: 	function() {
			$(this.element).find('div.jGrowl-notification').each(function(){
				$(this).trigger('jGrowl.beforeClose');
			});
		}
	});
	
	/** Reference the Defaults Object for compatibility with older versions of jGrowl **/
	$.jGrowl.defaults = $.fn.jGrowl.prototype.defaults;

